@import url("https://fonts.googleapis.com/css?family=Lobster");
@import url("https://fonts.googleapis.com/css?family=Bebas+Neue");
@import url("https://fonts.googleapis.com/css?family=Monoton");
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Quicksand");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=Pacifico");
@import url("https://fonts.googleapis.com/css?family=Oswald");
@import url("https://fonts.googleapis.com/css?family=Lato");

@import url("https://use.typekit.net/sml3dkn.css");
:root {
  --hold: "Pauline", "Ganache", "Ice Creame" "brevia";
  --splashTitleFont: "Nautilus Pompilius", sans-serif;
  --displayFont: "Nautilus Pompilius", sans-serif;
  --titleFont: brandon-grotesque, sans-serif;
  --subTitleFont: brandon-grotesque, sans-serif;
  --contentFont: brandon-grotesque, sans-serif;
  --accentColor: #ffffff;
  --baseColor: #99ffcc;
  --offBlack: black;
  --highlightColor: #99ffcc;
}

.basicPage {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: var(--offBlack);
  color: white;
  z-index: 0;
}

.appStoreBtn {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cacheHelpContainer {
  opacity: 0;
  width: 80%;
  height: 70%;
  animation: fadeIn 0.5s linear 1 forwards;
  animation-delay: 0.1s;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
}

.cacheHelpText {
  width: 100%;
  height: 20%;
  color: white;
  font-size: 2vh;
}

.cacheHelpBackButton {
  width: 50%;
  height: 20%;
  font-family: var(--titleFont);
  font-size: 4.75vh;
  background-color: var(--offBlack);
  border: 5px solid #151515;
  color: white;
  margin-bottom: 5%;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5vw;
}

.chattyMsg {
  font-family: var(--contentFont);
  color: white;
  font-style: italic;
  font-size: 2vh;
  margin-top: 2.5vh;
}

.lobbyReadyButton {
  width: 40%;
  height: 60%;
  font-family: var(--titleFont);
  font-size: 2vh;
  background-color: var(--offBlack);
  border: 5px solid #fff;
  color: white;
  margin: 1vh;
  border-radius: 2.5vw;
}

.fade-transition {
  transition: opacity 0.5s ease;
}

.lobbyContent {
  height: 85%;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.lobbyIntro {
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lobbyInstructions {
  margin: 5%;
  width: 90%;
}

.gridLobbyNameTag {
  height: 20%;
  width: 100%;
  font-size: 1.75vh;
}

.gridLobbyIcon {
  width: auto;
  height: 70%;
}

.lobbyGridUserCard {
  box-shadow: inset 0 0 0 1px #151515; /* Inset border effect */
  position: relative;
  width: auto;
  height: 100%;
}

.lobbyGrid {
  background-color: var(--offBlack);
  width: 100%;
  height: 40vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 25%));
  grid-auto-rows: 33%;
  grid-gap: 0px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.startGameButton {
  width: 70%;
  height: 9%;
  font-family: var(--titleFont);
  font-size: 3.25vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--accentColor);
  margin: 1%;
  border-radius: 2.5vw;
  align-self: center;
}

.startGameButton:active {
  background-color: white;
  color: black;
}

.iconSelectButton {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.header-container {
  padding: 0;
}

.lobbyUserInfo {
  display: flex;
  height: 100%;
  width: 100%-3;
  align-items: center;
  border: 5px solid #151515;
}

.lobbyUserIcon {
  float: left;
}

.turnSliderWrapper {
  width: var(--drWidth);
  margin-left: var(--drMargin);
}

/*
  RED FF3333
  ORANGE FF9933
  YELLOW FFFF66
  Green 66cc66
  Teal 99FFCC
  CYAN 33FFFF
  Blue 99CCFF
  Purple D58DFF
  DARK PINK FF6699
  PINK FFCCFF


  var(--color1),
  var(--color2),
  var(--color1),
  var(--color2),
  var(--color1));
*/

/* Styling for the gradient animation on the main box */
.ruleEvent::before {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0; /* Original dimensions of the parent */
  background: repeating-conic-gradient(
    from var(--a),
    var(--color1),
    var(--color2),
    var(--color1),
    var(--color2),
    var(--color1)
  );
  border-radius: 2.5vw; /* Matches the .ruleEvent border-radius */
  animation: rotating 4s linear infinite;
  /* Applying rotation animation */
}

/* Styling for the blurred gradient on the main box */
.ruleEvent::after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0; /* Original dimensions of the parent */
  background: repeating-conic-gradient(
    from var(--a),
    var(--color1),
    var(--color2),
    var(--color1),
    var(--color2),
    var(--color1)
  );
  border-radius: 2.5vw; /* Matches the .ruleEvent border-radius */
  animation: rotating 4s linear infinite;
  /* Applying rotation animation */

  opacity: 0.75;
}

/* Styling for the inner text container */
.ruleEventText {
  width: calc(100% - 4px); /* Smaller to reveal 4px inset on each side */
  height: calc(100% - 4px); /* Smaller to reveal 4px inset on each side */
  transition: color 0.5s linear;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 50;
  border-radius: calc(
    2.5vw - 2px
  ); /* Adjusted to stay consistent with the smaller dimensions */
  background-color: black;
  position: absolute;
  display: flex;

  inset: 2px; /* Creates the 4px gap */
}

/* Custom property for the rotation angle */
@property --a {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

/* Keyframes for the rotating animation */
@keyframes rotating {
  0% {
    --a: 0deg;
  }

  100% {
    --a: 360deg;
  }
}

.ruleEvent {
  left: 3%;
  position: absolute;
  height: 65.5%;
  width: 47%;
  max-width: 400px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 2.5vw;
  align-self: center;
  font-size: 1.65vh;
  opacity: 0;
  animation: fadeIn 1s linear 0.5s forwards;
  margin-top: 1%;
}

.joinSign {
  width: 30%;
  height: 100%;
  right: 0;
  position: absolute;
  border-radius: 2.5vw;
}

/* Font size for smaller viewports (less than 600px height) */
@media (max-height: 600px) {
  .joinCodeText {
    font-size: 10px;
  }
}

/* Font size for medium-sized viewports (between 600px and 800px height) */
@media (min-height: 601px) and (max-height: 800px) {
  .joinCodeText {
    font-size: 16px;
  }
}

/* Font size for larger viewports (greater than 800px height) */
@media (min-height: 801px) {
  .joinCodeText {
    font-size: 22px;
  }
}
.joinCodeText {
  position: absolute; /* Position text over the image */
  top: 55%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for perfect centering */
  padding: 20px; /* Add spacing around text */
  border-radius: 5px; /* Optional rounded corners */
  text-align: center; /* Center text alignment */
  width: 100%;
  color: var(--baseColor);
  text-shadow: 0px 0px 3px var(--baseColor);
}

.joinCodeOverlay {
  width: auto; /* Full width of `.joinSign` */
  height: 100%; /* Full height of `.joinSign` */
  position: relative; /* Allows absolute positioning inside it */
  object-fit: cover; /* Ensures image scales correctly */
}
.gameHeader {
  width: 100%;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  height: 7.5%;
  display: flex;
  background-color: transparent; /* or absolute, depending on your layout */
}

.turnSliderLabel {
  width: 100%;
  height: 5%;
  font-size: 2.5vh;
  font-family: var(--contentFont);
  color: #151515;
  text-align: left;
  justify-self: right;
  z-index: 3;
  margin-left: -5vw;
}

.turnSliderContainer {
  width: 100%;
  height: 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 3;
  transition: opacity 0.75s ease-in-out;
}
.turnSlider {
  height: 100%;
  width: 20vw;
  min-width: 20vw;
  border-top: 5px solid #151515;

  align-items: center;
  justify-content: center;
  z-index: 3;
}

.turnSliderIcon {
  width: 100%;
  z-index: 3;
  transition: filter 0.5s linear;
}

.turnSliderIcon.first {
  filter: grayscale(0%);
}

.turnSliderIcon.dull {
  filter: grayscale(100%) !important;
  opacity: 0.6;
}

.lobbyGameTitle {
  font-family: var(--titleFont);
  color: var(--baseColor);
  display: block;
  width: 100%;
  font-size: 3vh;
  margin-right: 30%;
}

.blinkSlowElement {
  animation: blinkOn 2s 0s 1;
}

.blinkFastElement {
  animation: blinkOn 1s 0s 1;
}

.pulsePlayerColor {
  animation: pulsePlayerColorAnimation 4s infinite linear;
}

.pulseElement {
  animation: pulseAnimation 4s infinite ease-in-out;
}

.subTitlePulseElement {
  animation: subTitlePulseAnimation 4s infinite linear;
}

.splashPreRenderContainer {
  top: 5.5vh;
  left: 0;
  width: 100vw;
  height: 94vh;
  z-index: -500;
  -webkit-transform: translateZ(-100px);
  transform: translateZ(-100px);
}

.cardSplashArt {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 0;
  animation: fadeOut 0.2s linear 1s 1 forwards;
}

.splashArt {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 0;
  animation: fadeOut 0.2s linear 2.7s 1 forwards;
}

@keyframes pulseAnimation {
  0% {
    text-shadow: 0 0 3px var(--baseColor);
  }
  50% {
    text-shadow: 0 0 9px var(--baseColor);
  }
  100% {
    text-shadow: 0 0 3px var(--baseColor);
  }
}

@keyframes pulsePlayerColorAnimation {
  0% {
    text-shadow: 0 0 3px var(--playerColor);
  }
  50% {
    text-shadow: 0 0 7px var(--playerColor);
  }
  100% {
    text-shadow: 0 0 3px var(--playerColor);
  }
}

@keyframes subTitlePulseAnimation {
  0% {
    text-shadow: 0 0 8px var(--baseColor);
  }
  50% {
    text-shadow: 0 0 1px var(--baseColor), 0 0 10px var(--baseColor);
  }
}

@keyframes pulseDiceAnimation {
  0% {
    text-shadow: 0 0 12px var(--baseColor);
  }
  50% {
    text-shadow: 0 0 5px var(--baseColor), 0 0 14px var(--baseColor);
  }
}

@keyframes slideLeft {
  80% {
    transform: translateX(-23vw);
  }
  100% {
    transform: translateX(-20vw);
  }
}

.reconnectBtn {
  width: 70%;
  height: 10%;
  font-family: var(--subTitleFont);
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid #fff;
  color: white;
  margin: 1vh;
  border-radius: 2.5vw;
  z-index: 2;
  align-self: center;
  text-align: center;
}

.lvlPickLable {
  font-size: 2.75vh;
  font-family: var(--titleFont);

  margin-bottom: 1.5vh;
  color: #99ffcc;
}

.lvlLable {
  font-size: 2vh;
  font-family: var(--contentFont);
  font-style: italic;
  white-space: pre-line;
  margin-bottom: 2%;
}

.lvlPickBtnContainer {
  width: 100%;
}
.lvlPickBtn {
  background-color: var(--offBlack);
  width: 33%;
  font-size: 7vh;
  border: 0px solid;
  transform: translateX(-5%);
}

.lvlContainer {
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lvlPickContainer {
  width: 100%;
  height: 30%;
  margin-bottom: 5vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.collapsedLvlPickContainer {
  width: 100%;
  border-bottom: 5px solid #151515;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 15%;
}

.titleWrapper {
  position: relative; /* Create a positioning context for child elements */
  overflow: hidden; /* To ensure the wave doesn't spill out of the wrapper */
}
.titleWave {
  position: relative;
}

.formGameTitle {
  font-family: var(--splashTitleFont);
  text-transform: none !important;
  text-shadow: 0 0 3px var(--baseColor);
  color: var(--baseColor);
  font-weight: 400 !important;
  animation: bobbing 10s ease-in-out infinite;
  display: block;
  width: auto;
  font-size: 6.5vh;
  text-align: center;
  white-space: pre-line;
}

@keyframes bobbing {
  0% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(2deg);
  }
}

.bannerText {
  font-family: var(--titleFont);
  font-size: 3vh;
  text-shadow: 0 0 10px var(--playerColor, #99ffcc);
  color: var(--playerColor, #99ffcc);
  transition: color 1s linear, text-shadow 1s linear;
}

.ruleDisplay {
  font-family: var(--subTitleFont);
  font-size: 2vh;
  color: white;
  position: absolute;
  right: 5px;
  max-width: 40vw;
  height: 5%;
  line-height: 100%;
  justify-content: center;
}

.altTitle {
  color: white;
}

.lobbyUserName {
  font-family: var(--displayFont);
  font-size: 3.25vh !important;
  text-shadow: none !important;
}

.promptSubtitle {
  font-family: (var(--contentFont));
  font-size: 2.5vh;
  margin-top: 10%;
  font-style: italic;
  margin-bottom: 5vh;
  color: white;
}

.lobbyRoomCode {
  font-family: var(--titleFont);
  font-size: 4.5vh;
  text-shadow: 0 0 8px var(--baseColor);
  color: var(--baseColor);
  display: inline-block;
}

.lobbyRoomCodePrompt {
  font-family: var(--titleFont);
  font-size: 4.5vh;
  color: var(--accentColor);
  display: inline-block;
}

.gamePiecePrompt {
  font-family: var(--displayFont);
  text-transform: none !important;
  font-size: 4vh;
  color: var(--baseColor);
  border: none;
}
.labelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 60%;
  height: 100%;
}

.lobbyLogo {
  margin: 1.25vh;
  animation: bob1 7s linear infinite;
}

.labelText {
  font-size: 2.5vh;
  margin-bottom: 1%;
  color: gray;
  width: 100%;
  text-align: left;
  font-family: var(--contentFont);
  display: flex;
}
.charactersRemaining {
  font-size: 2.5vh;
  color: gray;
  right: 0;
  width: 100%;
  text-align: right;
  font-family: var(--contentFont);
}

.formOtpInput {
  width: 25% !important;
  height: 100%;
  font-family: var(--subTitleFont);
  font-size: 4vh;
  padding: 0;
  border-radius: 2.5vw;
}

.formOtpContainer {
  width: 100%;
  height: 50%;
  justify-content: center;
  overflow-wrap: hidden;
  column-gap: 4%;
  padding: 0;
}
.appStoreAccentPrompt {
  justify-content: center;
  display: inline-block;
  font-size: 2.5vh;
  white-space: pre-line;
  color: var(--baseColor);
  text-shadow: 0 0 3px var(--baseColor);
  font-family: var(--subTitleFont);
  margin-bottom: 3vh;
}
.appStorePrompt {
  justify-content: center;
  display: inline-block;
  font-size: 2.5vh;
  white-space: pre-line;
  color: var(--accentColor);
  text-shadow: 0 0 3px var(--accentColor);
  font-family: var(--subTitleFont);
  margin-top: 3vh;
}

.storeButton {
  width: 200px;
  height: auto;
  margin: 10px;
}

.appStoreContainer {
  opacity: 0;
  width: 90%;
  height: 70%;
  animation: fadeIn 0.5s linear 1 forwards;
  animation-delay: 0.1s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formInput {
  width: 100%;
  height: 50%;
  font-family: var(--contentFont);
  font-size: 3vh;
  border-radius: 2.5vw;
  padding: 3%;
}

.appStoreBackButton {
  width: 50%;
  height: 12.5%;
  font-family: var(--titleFont);
  font-size: 4.75vh;
  background-color: var(--offBlack);
  border: 5px solid #151515;
  color: white;
  margin: 5%;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5vw;
  margin-top: 5vh;
}

.orHostLabel {
  color: gray;
  width: 100%;
  flex: 1;
  font-family: var(--contentFont);
  font-size: 2.5vh;
  display: block;
}

.hostLink {
  color: white;
  font-family: var(--displayFont);
  margin: 5%;
  text-decoration: underline;
  text-transform: none !important;
  font-size: 3vh;
  width: 100%;
  flex: 1;
}

.formButton {
  width: 45%;
  height: 35%;
  font-family: var(--displayFont);
  font-size: 3.75vmax;
  background-color: var(--offBlack);
  border: 5px solid #151515;
  color: gray;
  padding: 2%;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5vw;
  text-transform: none !important;
  margin-top: 5%;
}

.formButton.open {
  color: white;
  border-color: var(--baseColor);
}

.formButton.hostButton {
  color: #151515;
  border-color: #151515;
}

.formButton:active {
  background-color: white;
  color: black;
}

.turnPlayerIcon {
  width: var(--drWidth);
  height: var(--drWidth);
  animation: drink-shaking 2s infinite linear;
  transition: opacity 1.5s ease-in-out;
  z-index: 3;
}

.lvlButton {
  width: 40%;
  height: 10%;
  font-family: var(--titleFont);
  font-size: 3.5vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--accentColor);
  margin-top: 5vh;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 2.5vw;
}

.happyHourIdlePlayer {
  height: auto;
  width: 35%;
  z-index: 1;
}

.happyHourSign {
  height: 10%;
  width: 100%;
  font-family: var(--displayFont);
  text-transform: none !important;
  font-size: 5vh;
  text-align: center;
  transition: opacity 1.5s ease-out;
  display: flex;
  padding-top: 10vh;
  margin-bottom: 5vh;
  justify-content: center;
}

.startMiniGame {
  width: 65%;
  height: 12.5%;
  font-family: var(--displayFont), sans-serif;
  text-transform: none !important;
  font-size: 4.5vh;
  animation: fadeInButton 0.5s linear forwards; /* focus-in 1s linear 0s 1 forwards, animate-pulse-focus 2s linear 1.1s infinite; TODO */
  background-color: black;
  border: 5px solid var(--baseColor);
  color: var(--accentColor);
  border-radius: 2.5vw;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 17.5%;
  z-index: 500;
}

.pulse-focus {
  animation: focus-in 0.2s linear 0s 1 forwards,
    animate-pulse-focus 2s linear 0.2s infinite !important;
}

.rollBtn {
  width: 70%;
  height: 15%;
  font-family: var(--displayFont), sans-serif;
  text-transform: none !important;
  font-size: 7vh;
  animation: animate-pulse 2s linear infinite;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--baseColor);
  text-shadow: 0 0 10px var(--baseColor);
  border-radius: 2.5vw;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 42.5%;
  left: 15%;
}

.rollBtn:active {
  background-color: white;
  color: black;
}

.alertBtn {
  width: 90%;
  height: 55%;
  font-family: var(--subTitleFont), sans-serif;
  font-size: 3vh;
  background-color: var(--offBlack);
  border: 5px solid var(--baseColor);
  color: var(--accentColor);
  border-radius: 2.5vw;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30%;
  left: 5%;
  z-index: 5;
  white-space: pre-line;
  box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.8);
}

.stopBtn {
  width: 45%;
  height: 75%;
  font-family: var(--titleFont);
  font-size: 4vh;
  color: white;
  background-color: var(--offBlack);
  border: 5px solid #fff;
  animation: flashText 0.75s infinite;
}

@keyframes flashBackground {
  0% {
    background-color: var(--baseColor);
  }
  50% {
    background-color: var(--offBlack);
  }
  100% {
    background-color: var(--baseColor);
  }
}

@keyframes animate-subtle-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    text-shadow: 0 0 2px var(--baseColor);
  }
  40% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    text-shadow: 0 0 4px rgba(255, 255, 255, 0);
  }
  80% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    text-shadow: 0 0 4px var(--baseColor);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    text-shadow: 0 0 2px var(--baseColor);
  }
}

@keyframes animate-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(153, 255, 204, 0.7);
    text-shadow: 0 0 10px var(--accentColor);
  }
  40% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px var(--accentColor);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    text-shadow: 0 0 10px var(--accentColor);
  }
}

@keyframes focus-in {
  0% {
    box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.65);
  }
}

@keyframes focus-out {
  0% {
    box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.65);
  }
  100% {
    box-shadow: none;
  }
}

@keyframes animate-pulse-focus {
  0% {
    box-shadow: 0 0 0 0 rgba(153, 255, 204, 0.7),
      0 0 0 100vh rgba(0, 0, 0, 0.65);
    text-shadow: 0 0 10px var(--accentColor);
  }
  40% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0),
      0 0 0 100vh rgba(0, 0, 0, 0.65);
    text-shadow: 0 0 3px var(--accentColor);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0),
      0 0 0 100vh rgba(0, 0, 0, 0.65);
    text-shadow: 0 0 3px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 0 100vh rgba(0, 0, 0, 0.65);
    text-shadow: 0 0 10px var(--accentColor);
  }
}

@keyframes animate-pulse-teal {
  0% {
    box-shadow: 0 0 0 0 var(--baseColor);
    text-shadow: 0 0 10px var(--baseColor);
  }
  40% {
    box-shadow: 0 0 0 30px rgba(153, 255, 204, 0);
    text-shadow: 0 0 3px rgba(153, 255, 204, 0);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(153, 255, 204, 0);
    text-shadow: 0 0 3px var(--baseColor);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(153, 255, 204, 0);
    text-shadow: 0 0 10px var(--baseColor);
  }
}

@keyframes animate-pulse-highlight1 {
  0% {
    box-shadow: 0 0 0 0 var(var(--baseColor));
    text-shadow: 0 0 10px var(--baseColor);
  }
  40% {
    box-shadow: 0 0 0 30px rgba(153, 255, 204, 0);
    text-shadow: 0 0 3px rgba(153, 255, 204, 0);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(153, 255, 204, 0);
    text-shadow: 0 0 3px var(--baseColor);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(153, 255, 204, 0);
    text-shadow: 0 0 10px var(--baseColor);
  }
}

@keyframes animate-pulse-highlight-roulette {
  50% {
    color: #151515;
    box-shadow: 0 15px 15px black;
  }
}

@keyframes animate-pulse-highlight {
  0% {
    color: var(--accentColor);
    text-shadow: 0 0 0px var(--accentColor);
    box-shadow: 0 0 0 0 var(--accentColor);
    border-color: var(--accentColor);
  }
  40% {
    color: var(--accentColor);
    text-shadow: 0 0 5px var(--accentColor);
    box-shadow: 0 0 0 0 var(--accentColor);
    border-color: var(--accentColor);
  }
  80% {
    color: #151515;
    text-shadow: 0 0 5px rgba(153, 255, 204, 0);
    box-shadow: 0 0 0 10px rgba(153, 255, 204, 0);
    border-color: #151515;
  }
  100% {
    color: var(--accentColor);
    text-shadow: 0 0 0px var(--accentColor);
    box-shadow: 0 0 0 0 rgba(153, 255, 204, 0);
    border-color: var(--accentColor);
  }
}

@keyframes animate-pulse-white {
  0% {
    box-shadow: 0 0 0 0 white;
  }
  40% {
    box-shadow: 0 0 0 30px rgba(255, 109, 74, 0);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(255, 109, 74, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0);
  }
}
@keyframes animate-pulse-dice {
  0% {
    box-shadow: 0 0 0 0 white;
    text-shadow: 0 0 10px white;
    color: white;
    border-color: white;
  }
  40% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px var(--baseColor);
    color: var(--baseColor);
    border-color: var(--baseColor);
  }
  80% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px var(--baseColor);
    color: var(--baseColor);
    border-color: var(--baseColor);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0);
    border-color: var(--baseColor);
    color: var(--baseColor);
  }
}

@keyframes animate-pulse-cursed-dice {
  0% {
    box-shadow: 0 0 0 0 magenta;
    text-shadow: 0 0 10px white;
    border-color: white;
    color: white;
  }
  40% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px magenta;
    border-color: magenta;
    color: magenta;
  }
  80% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    text-shadow: 0 0 3px magenta;
    border-color: magenta;
    color: magenta;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0);
    border-color: magenta;
    color: magenta;
  }
}

@keyframes flashText {
  0% {
    color: white;
  }
  50% {
    color: var(--accentColor);
  }
  100% {
    color: white;
  }
}

.formGameTitle .fast {
  animation: blink 0.3s 1s 8;
}
.formGameTitle .flickerOn {
  animation: blinkOn 2s 0s 1;
}

.formGameTitle .delay {
  animation: blink 2.5s 1.5s 8;
}

.formGameTitle .delayeder {
  animation: blink 6s 3.25s infinite;
}

@keyframes blink {
  78% {
    color: inherit;
  }
  79% {
    color: var(--offBlack);
  }
  80% {
  }
  81% {
    color: inherit;
  }
  82% {
    color: var(--offBlack);
  }
  83% {
    color: inherit;
  }
  92% {
    color: var(--offBlack);
  }
  92.5% {
    color: inherit;
  }
}

@keyframes blinkOn {
  0% {
    opacity: 0%;
  }
  20% {
    opacity: 0%;
  }
  21% {
    opacity: 50%;
  }
  22% {
    opacity: 10%;
  }
  24% {
    opacity: 75%;
  }
  30% {
    opacity: 0%;
  }
  50% {
    opacity: 100%;
  }
  90% {
    opacity: 100%;
  }
  91% {
    opacity: 0;
  }
  93% {
    opacity: 100%;
  }
  94% {
    opacity: 0%;
  }
  95% {
    opacity: 100%;
  }
  97% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.lvlSlider {
  -webkit-appearance: none;
  width: 60%;
  height: 50%;
  background: #151515;
  border-radius: 5%/95%;
  z-index: 1;
}

.lvlSlider::before {
  content: "";
  width: calc(30% * (var(--val))); /* Calculate the width based on the value */
  height: 1%;
  background: var(--baseColor); /* Fill color */
  position: absolute;
  border-radius: 5%/95%;
  z-index: 0;
}

.lvlSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 4.5vh;
  height: 4.5vh;
  background: var(--baseColor); /* Thumb color */
  border-radius: 50%;
}

.sliderScale {
  width: 100%;
  justify-content: space-between;
  font-size: 4.5vh;
  height: 10%;
}

.slider-values {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 5px; /* Adjust this margin as needed */
  width: 90%;
  height: 10%;
}

@keyframes circle {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-175vh);
    transform: translateY(-175vh);
  }
}

@keyframes floatOff {
  0% {
    opacity: 100;
    -webkit-transform: translateY(0);
    transform: translateY(0) rotate(0deg);
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-150vh);
    transform: translateY(-150vh) rotate(70deg);
  }
}

.bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1 !important;
  -webkit-transform: translateZ(-1px);
  transform: translateZ(-1px);
}

.bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  border-color: var(--playerColor, white);
  bottom: -100px;
  animation: circle 5s infinite;
  transition-timing-function: linear;
  border-radius: 50%;
  border: solid 2px var(--playerColor, white);
  z-index: -5;
}

.bubbles li:nth-child(1) {
  width: 8px;
  height: 8px;
  left: 25%;
  animation-delay: 1.5s;
  animation-duration: 11s;
}

.bubbles li:nth-child(2) {
  width: 14px;
  height: 14px;
  left: 40%;
  animation-delay: 0.5s;
  animation-duration: 11s;
}

.bubbles li:nth-child(3) {
  width: 11px;
  height: 11px;
  left: 60%;
  animation-delay: 2s;
  animation-duration: 10s;
}

.bubbles li:nth-child(4) {
  width: 16px;
  height: 16px;
  left: 80%;
  animation-delay: 0s;
  animation-duration: 15s;
}

.bubbles li:nth-child(5) {
  width: 11px;
  height: 11px;
  left: 50%;
  animation-delay: 3s;
  animation-duration: 9s;
}

.bubbles li:nth-child(6) {
  width: 9px;
  height: 9px;
  left: 10%;
  animation-delay: 2s;
  animation-duration: 8s;
}

.bubbles li:nth-child(7) {
  width: 13px;
  height: 13px;
  left: 15%;
  animation-delay: 1s;
  animation-duration: 9s;
}

.bubbles li:nth-child(8) {
  width: 8px;
  height: 8px;
  left: 30%;
  animation-delay: 2.5s;
  animation-duration: 9s;
}

.bubbles li:nth-child(9) {
  width: 13px;
  height: 13px;
  left: 30%;
  animation-delay: 1.5s;
  animation-duration: 9s;
}

.bubbles li:nth-child(10) {
  width: 5px;
  height: 5px;
  left: 70%;
  animation-delay: 0.5s;
  animation-duration: 5s;
}

.bubbles li:nth-child(11) {
  width: 11px;
  height: 11px;
  left: 15%;
  animation-delay: 0.75s;
  animation-duration: 7s;
}

.bubbles li:nth-child(12) {
  width: 8px;
  height: 8px;
  left: 80%;
  animation-delay: 0.25s;
  animation-duration: 6s;
}

.bubbles-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
  -webkit-transform: translateZ(1px);
  transform: translateZ(1px);
  opacity: 0;
  animation: fadeIn 0.5s linear 1 forwards;
  animation-delay: 1.1s;
}

.bubbles-card li {
  position: absolute;
  list-style: none;
  display: block;
  border-color: var(--bubble-color, rgba(0, 0, 0, 0.3));
  bottom: -100px;
  animation: circle 5s infinite;
  transition-timing-function: linear;
  border-radius: 50%;
  border: solid 2px var(--bubble-color, rgba(0, 0, 0, 0.3));
  z-index: 1;
}

.bubbles-card li:nth-child(1) {
  width: 18px;
  height: 18px;
  left: 25%;
  animation-delay: 1.5s;
  animation-duration: 16s;
}

.bubbles-card li:nth-child(2) {
  width: 24px;
  height: 24px;
  left: 40%;
  animation-delay: 0.5s;
  animation-duration: 16s;
}

.bubbles-card li:nth-child(3) {
  width: 21px;
  height: 21px;
  left: 60%;
  animation-delay: 2s;
  animation-duration: 15s;
}

.bubbles-card li:nth-child(4) {
  width: 26px;
  height: 26px;
  left: 80%;
  animation-delay: 0s;
  animation-duration: 20s;
}

.bubbles-card li:nth-child(5) {
  width: 21px;
  height: 21px;
  left: 50%;
  animation-delay: 3s;
  animation-duration: 14s;
}

.bubbles-card li:nth-child(6) {
  width: 19px;
  height: 19px;
  left: 10%;
  animation-delay: 2s;
  animation-duration: 13s;
}

.bubbles-card li:nth-child(7) {
  width: 23px;
  height: 23px;
  left: 15%;
  animation-delay: 1s;
  animation-duration: 14s;
}

.bubbles-card li:nth-child(8) {
  width: 18px;
  height: 18px;
  left: 30%;
  animation-delay: 2.5s;
  animation-duration: 14s;
}

.bubbles-card li:nth-child(9) {
  width: 23px;
  height: 23px;
  left: 30%;
  animation-delay: 1.5s;
  animation-duration: 14s;
}

.bubbles-card li:nth-child(10) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-delay: 0.5s;
  animation-duration: 10s;
}

.bubbles-card li:nth-child(11) {
  width: 21px;
  height: 21px;
  left: 15%;
  animation-delay: 0.75s;
  animation-duration: 12s;
}

.bubbles-card li:nth-child(12) {
  width: 18px;
  height: 18px;
  left: 80%;
  animation-delay: 0.25s;
  animation-duration: 11s;
}

.bubbles-card li:nth-child(13) {
  width: 18px;
  height: 18px;
  left: 30%;
  animation-delay: 6s;
  animation-duration: 10s;
}

.bubbles-card li:nth-child(14) {
  width: 23px;
  height: 23px;
  left: 50%;
  animation-delay: 5s;
  animation-duration: 14s;
}

.bubbles-card li:nth-child(15) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-delay: 5.5s;
  animation-duration: 10s;
}

.bubbles-card li:nth-child(16) {
  width: 21px;
  height: 21px;
  left: 15%;
  animation-delay: 8s;
  animation-duration: 12s;
}

.bubbles-card li:nth-child(17) {
  width: 18px;
  height: 18px;
  left: 80%;
  animation-delay: 7s;
  animation-duration: 11s;
}

.bubbles-hh {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
  -webkit-transform: translateZ(1px);
  transform: translateZ(1px);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  animation: fadeIn 0.5s linear 1 forwards;
}

.bubbles-hh.hidden {
  opacity: 0 !important;
}

.bubbles-hh li {
  position: absolute;
  list-style: none;
  display: block;
  border-color: var(--bubble-color, rgba(0, 0, 0, 0.3));
  bottom: -100px;
  animation: circle 5s infinite;
  transition-timing-function: linear;
  border-radius: 50%;
  border: solid 2px var(--bubble-color, rgba(0, 0, 0, 0.3));
  z-index: 1;
}

.bubbles-hh li:nth-child(1) {
  width: 21px;
  height: 21px;
  left: 25%;
  animation-delay: 1.5s;
  animation-duration: 16s;
}

.bubbles-hh li:nth-child(2) {
  width: 27px;
  height: 27px;
  left: 40%;
  animation-delay: 0.5s;
  animation-duration: 16s;
}

.bubbles-hh li:nth-child(3) {
  width: 24px;
  height: 24px;
  left: 60%;
  animation-delay: 2s;
  animation-duration: 15s;
}

.bubbles-hh li:nth-child(4) {
  width: 29px;
  height: 29px;
  left: 80%;
  animation-delay: 0s;
  animation-duration: 20s;
}

.bubbles-hh li:nth-child(5) {
  width: 24px;
  height: 24px;
  left: 50%;
  animation-delay: 3s;
  animation-duration: 14s;
}

.bubbles-hh li:nth-child(6) {
  width: 22px;
  height: 22px;
  left: 10%;
  animation-delay: 2s;
  animation-duration: 13s;
}

.bubbles-hh li:nth-child(7) {
  width: 26px;
  height: 26px;
  left: 15%;
  animation-delay: 1s;
  animation-duration: 14s;
}

.bubbles-hh li:nth-child(8) {
  width: 21px;
  height: 21px;
  left: 30%;
  animation-delay: 2.5s;
  animation-duration: 14s;
}

.bubbles-hh li:nth-child(9) {
  width: 26px;
  height: 26px;
  left: 30%;
  animation-delay: 1.5s;
  animation-duration: 14s;
}

.bubbles-hh li:nth-child(10) {
  width: 18px;
  height: 18px;
  left: 70%;
  animation-delay: 0.5s;
  animation-duration: 10s;
}

.bubbles-hh li:nth-child(11) {
  width: 24px;
  height: 24px;
  left: 15%;
  animation-delay: 0.75s;
  animation-duration: 12s;
}

.bubbles-hh li:nth-child(12) {
  width: 21px;
  height: 21px;
  left: 80%;
  animation-delay: 0.25s;
  animation-duration: 11s;
}

.bubbles-hh li:nth-child(13) {
  width: 15px;
  height: 15px;
  left: 50%;
  animation-delay: 8s;
  animation-duration: 10s;
}

.bubbles-hh li:nth-child(14) {
  width: 24px;
  height: 24px;
  left: 65%;
  animation-delay: 7s;
  animation-duration: 12s;
}

.bubbles-hh li:nth-child(15) {
  width: 21px;
  height: 21px;
  left: 80%;
  animation-delay: 7.5s;
  animation-duration: 11s;
}

.bubbles-hh li:nth-child(16) {
  width: 18px;
  height: 18px;
  left: 10%;
  animation-delay: 8.5s;
  animation-duration: 10s;
}

.bubbles-hh li:nth-child(17) {
  width: 24px;
  height: 24px;
  left: 15%;
  animation-delay: 7.75s;
  animation-duration: 12s;
}

.bubbles-hh li:nth-child(18) {
  width: 21px;
  height: 21px;
  left: 40%;
  animation-delay: 8.25s;
  animation-duration: 11s;
}

.bubbles-hh li:nth-child(19) {
  width: 18px;
  height: 18px;
  left: 35%;
  animation-delay: 9s;
  animation-duration: 6s;
}

.bubbles-hh li:nth-child(20) {
  width: 21px;
  height: 21px;
  left: 60%;
  animation-delay: 9.75s;
  animation-duration: 11s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInButton {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
